import appComponents from '@/router/components';

const routes = [
    {
        path: '/prompt-ai-licenses',
        name: 'client.prompt.ai.licenses',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.prompt_ai.aiLicenses,
        },
        meta: {
            title: 'Prompt AI Licenses',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },

    {
        path: '/prompt-ai-licenses/:license_Key/usages',
        name: 'client.prompt.ai.licenses.usages',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.prompt_ai.aiLicensesUsages,
        },
        meta: {
            title: 'Licenses Usages',
            required_auth:true,
            required_permission:true,
            permissions:['view_client_data']
        },
    },




    {
        path: '/admin/settings/prompt-ai/api-keys',
        name: 'admin.settings.prompt.ai.api.keys',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.ai_settings.apiKeys,
        },
        meta: {
            title: 'AI API Keys',
            required_auth: true,
            required_permission: true,
            permissions: ['view_schedule_commands']
        },
    },

    {
        path: '/admin/settings/prompt-ai/licenses',
        name: 'admin.settings.prompt.ai.licenses',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.ai_settings.licenses,
        },
        meta: {
            title: 'AI Licenses',
            required_auth: true,
            required_permission: true,
            permissions: ['view_schedule_commands']
        },
    },


    {
        path: '/admin/settings/prompt-ai/licenses/:license_Key/usages',
        name: 'admin.settings.prompt.ai.licenses.usages',
        components: {
            AuthHeader: appComponents.access_control.layouts.auth_header,
            SidebarLeft: appComponents.access_control.layouts.sidebar_left,
            Content: appComponents.ai_settings.usages,
        },
        meta: {
            title: 'Licenses Usages',
            required_auth: true,
            required_permission: true,
            permissions: ['view_schedule_commands']
        },
    },

];

export default routes;

