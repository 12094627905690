import _ from 'lodash';
const application = [];

import accessControlRoutes from "@accessControlSrc/router/components"
import connectedAccountsRoutes from "@connectedAccountsSrc/router/components"
import promptAiLicensesRoutes from "@promptAiLicensesSrc/router/components"

let components = _.merge(application, accessControlRoutes, connectedAccountsRoutes, promptAiLicensesRoutes);

export default components;
